import './App.css'
import Navbar from "./components/navbar/navbar";
import Home from "./components/home/home";
import ParticlesPanel from "./components/particles/particles-panel";
import About from "./components/about/about";
import Education from "./components/education/education";
import Experience from './components/experience/experience';
// import Skills from "./components/skills/skills";
//import Projects from "./components/projects/projects";

export default function App() {
    return(
        <main className="appContainer">
            <ParticlesPanel />
  
            <Navbar />
            <Home />
            <About />
            <Education />
            <Experience />
            {/* <Skills /> */}
            {/*<Projects />*/}
        </main>
    )
}
