import {makeStyles} from "@mui/styles";

const HomeStyles = makeStyles((theme) => ({
    size: {
        height: "100vh"
    },
    title: {
        display: "flex",
        alignItems: "center",
        height: "100vh"
    },
    iconButtonBorder: {
        '&:hover': {
            backgroundColor: "#FFFFFF",
            color: "#ffffff",
            transition: "0.3s"
        }
    },
    iconButton: {
        width: "5vh",
        height: "auto",
        color: "#ffffff",
        '&:hover': {
            color: "#000000",
            transition: "0.3s"
        }
    },
    titleText: {
        color: theme.palette.primary.main
    },
    inline: {
        color: theme.palette.primary.main,
        fontFamily: "Poppins",
        fontSize: "1.6rem"
    },
    photoContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    photo: {
        width: "80%",
        borderRadius: "50%",
        "-webkit-filter": "grayscale(100%)",
        filter: "grayscale(100%)"
    }
}))

export default HomeStyles;