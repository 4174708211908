import {Card, CardContent, Typography} from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';

const CourseCard = ({ name, authors, provider }) => {
  return (
    <Card sx={{ minWidth: 50 }}>
      <CardContent>
        <Typography variant="h6" component="div">
          {name}
        </Typography>
        <br />
        <span style={{ display: "inline-flex" }}>
          <Typography variant="body2">{provider}</Typography>
          <CircleIcon sx={{ color: "#f61329", transform: "scale(0.3)" }} />
          <Typography variant="body2">{authors.join(", ")}</Typography>
        </span>
      </CardContent>
    </Card>
  );
};

export default CourseCard;
