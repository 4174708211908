import {makeStyles} from "@mui/styles";

const ExperienceStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.primary.main,
        fontFamily: "Poppins, Roboto, Arial, sans-serif"
    },
    title: {
        textAlign: "center",
        paddingTop: "3%",
        color: theme.palette.secondary.dark
    },

    bottom: {
        paddingBottom: "7%"
    }
}));

export default ExperienceStyles;