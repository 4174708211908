import { makeStyles } from "@mui/styles";

const NavbarStyles = makeStyles((theme) => ({
    link: {
        marginRight: "3%",
        display: "inline-block"
    },
    tabsTransparent: {
        fontFamily: "Poppins",
        fontSize: "110%",
        fontWeight: "600",
        color: "#ffffff",
        '&:hover': {
            transition: "0.1s",
            color: "#000000",
            borderBottom: "3px solid #000000",
        }
    },
    tabsSolid: {
        fontFamily: "Poppins",
        fontSize: "110%",
        fontWeight: "600",
        color: "#000000",
        '&:hover': {
            transition: "0.5s",
            color: "#f61329",
            borderBottom: "3px solid #f61329"
        }
    }
}));

export default NavbarStyles;