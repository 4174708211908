import { Grid, Typography } from "@mui/material";
import ExperienceStyles from "./experience-styles";
import { Chrono } from "react-chrono";
import data from "./experiences.json";

const Experience = () => {
  const classes = ExperienceStyles();
  const {experiences} = data;

  return (
    <Grid container className={classes.root} id="experience">
      <Grid item className={classes.title} xs={12}>
        <br />
        <Typography variant="h2">Professional Experience</Typography>
        <br />
        <br />
      </Grid>

      <Grid item xs={1} />
      <Grid item xs={10}>
        <Chrono 
          items={experiences}
          itemWidth={150}
          timelinePointDimension={0}
          mode="VERTICAL_ALTERNATING"
          borderLessCards={true}
          disableClickOnCircle={true}
          disableTimelinePoint={true}
          hideControls={true}
          theme={{
            primary: "#f61329",
            secondary: "#ffffff",
            cardTitleColor: "#f61329",
            titleColorActive: "#f61329",
            titleColor: "#f61329",
          }}
        />
      </Grid>
      <Grid item xs={1} />

      <Grid item xs={12}>
        <div className={classes.bottom} />
      </Grid>
    </Grid>
  );
};

export default Experience;
