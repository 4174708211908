import {makeStyles} from "@mui/styles";

export const EducationSkills = makeStyles((theme) => ({
    root: {
        background: theme.palette.primary.dark
    },
    title: {
        textAlign: "center",
        paddingTop: "2%",
        color: theme.palette.secondary.dark
    },
    subtitle: {
        color: theme.palette.secondary.dark,
        textAlign: "center",
        fontWeight: "600"
    },
    logo: {
        height: "30px",
        width: "auto"
    }
}));

export default EducationSkills;