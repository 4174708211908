import { Button, Divider, Grid, Typography } from "@mui/material";
import AboutStyles from "./about-styles";

import photo from "./profilePhoto.jpg";
import Resume from "curriculum-files/CVPedroMatos.pdf";
import { FaRegFilePdf } from "react-icons/fa";

export default function About() {
  const classes = AboutStyles();
  const age =
    new Date(new Date() - new Date("08-03-1998").getTime()).getFullYear() -
    1970;

  return (
    <Grid container className={classes.root} id="about">
      <Grid item className={classes.title} xs={12}>
        <br />
        <Typography variant="h2">About Me</Typography>
        <Typography variant="h4">Get to know me</Typography>
        <br />
        <br />
      </Grid>

      <Grid item xs={1} />

      <Grid item xs={5} md={5}>
        <div className={classes.pictureSpace}>
          <img src={photo} className={classes.picture} alt="profile" />
        </div>
        <br />
        <br />
        <br />
      </Grid>

      <Grid item xs={5} md={5}>
        <br />
        <Typography variant="h6" className={classes.subTitle} display="inline">
          <span>My name is Pedro Matos and I am a</span>

          <span style={{ color: "#f61329" }}>&nbsp;Software Engineer</span>
          <span>.</span>
        </Typography>

        <div className={classes.space} />
        <Typography variant="body1">
          I am {age} years old and live in Figueira da Foz, Portugal. Currently,
          I am working at Nokia Networks, developing and maintaining web
          applications.
          <br />
          <br />
          My areas of interest are, besides web applications development
          &#128187;, Artificial Intelligence and Machine Learning &#128200;.
          <br />
          <br />
          My hobbies include listening to music &#127911;, playing guitar and
          bass &#127928; and watching movies &#128250;. Finally, I am a bookworm
          &#128218;, so it is probable to find me reading a book while
          sunbathing on the beach &#127958;.
        </Typography>
        <br />
        <Divider />
        <br />

        <a
          rel="noreferrer"
          target="_blank"
          href={Resume}
          className={classes.link}
        >
          <div>
            <Button
              variant="contained"
              size="large"
              className={classes.button}
              startIcon={<FaRegFilePdf />}
            >
              Download CV
            </Button>
          </div>
        </a>
      </Grid>
      <Grid item xs={1} />

      <Grid item xs={12}>
        <div className={classes.bottom} />
      </Grid>
    </Grid>
  );
}
