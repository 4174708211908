import HomeStyles from "./home-styles";
import Typewriter from 'typewriter-effect';
import {Grid, IconButton, Typography, useMediaQuery} from "@mui/material";
import {FaGithub, FaLinkedin} from "react-icons/fa";
import profilePhoto from './profilePhoto2.jpg';

export default function Home() {

    const classes = HomeStyles();
    const desktop = useMediaQuery('(min-width: 960px)');
    const mobile = useMediaQuery('(min-width: 380px)');

    return(
        <div id="home" className={classes.size}>
            <Grid container>
                <Grid item xs={1} />

                <Grid item xs={5}>
                    <div className={classes.title}>
                        <div>
                            {/* GitHub icon */}
                            <a rel="noreferrer" href="https://github.com/pedrodlmatos" target="_blank">
                                <IconButton
                                    sx={{ marginRight: "10px", backgroundColor: "none" }}
                                    className={classes.iconButtonBorder}
                                    aria-label="github"
                                >
                                    <FaGithub className={classes.iconButton} />
                                </IconButton>
                            </a>

                            {/* LinkedIn icon */}
                            <a rel="noreferrer" href="https://linkedin.com/in/pedrodlmatos" target="_blank">
                                <IconButton
                                    sx={{ marginRight: "10px", backgroundColor: "none" }}
                                    className={classes.iconButtonBorder}
                                    aria-label="linkedin"
                                >
                                    <FaLinkedin className={classes.iconButton} />
                                </IconButton>
                            </a>

                            <br />
                            <br />
                            <div style={{ paddingBottom: "2%" }} />

                            {/* Name */}
                            <Typography className={classes.titleText} variant="h3">
                                <b>Hi there! I am</b>
                            </Typography>
                            <br />
                            <Typography className={classes.titleText} variant="h1">
                                Pedro Matos
                            </Typography>

                            {/* Roles */}
                            <div className={classes.inline}>
                                {mobile && (
                                    <p style={{ display: "inline-block" }}><b>I am a</b>&nbsp;</p>
                                )}

                                {mobile && (
                                    <div style={{ display: "inline-block" }}>
                                        <b>
                                            <Typewriter options={{
                                                strings: ['Software Developer', 'Fronted Engineer'],
                                                autoStart: true,
                                                loop: true
                                            }}/>
                                        </b>
                                    </div>
                                )}
                                <br />
                            </div>
                        </div>
                    </div>
                </Grid>

                <Grid className={classes.photoContainer} item xs={5}>
                    {desktop && (
                        <img src={profilePhoto} className={classes.photo} alt="pedro matos"/>
                    )}
                </Grid>
            </Grid>
        </div>
    )
}