import { Grid, Link, Typography } from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import EducationStyles from "./education-styles";
import uaLogo from "./ua-logo.png";
import CourseCard from "./courseCard";

export default function Education() {
  const classes = EducationStyles();

  return (
    <Grid container id="education" className={classes.root}>
      <Grid className={classes.title} item xs={12}>
        <br />
        <Typography variant="h2">Education</Typography>
        <br />
        <br />
      </Grid>

      <Grid item xs={6}>
        <Typography className={classes.subtitle} variant="h3">
          Academic Background
        </Typography>
        <Timeline position="left">
          <TimelineItem>
            <TimelineOppositeContent sx={{ m: "auto 0" }} align="right">
              <Typography variant="h5" component="span">
                Master in Software Engineering
              </Typography>

              <Typography variant="body2" color="text.secondary">
                The Master's Degree in Software Engineering deepens the
                knowledge acquired previously while providing others on more
                specific topics or from other areas.
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot color="primary" variant="filled">
                <img src={uaLogo} alt="UA" className={classes.logo} />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>

            <TimelineContent sx={{ py: "65px", px: 2 }}>
              <Typography variant="h5" component="span">
                University of Aveiro
              </Typography>
              <Typography variant="body2" color="#f61329">
                Sep 2019 - Dec 2021
              </Typography>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent sx={{ m: "auto 0" }} align="right">
              <Typography variant="h5" component="span">
                Bachelor in Software Engineering
              </Typography>

              <Typography variant="body2" color="text.secondary">
                The Bachelor's degree aims to provide a solid foundation in
                robust, well-organized software development adopting current
                best practices and concepts.
              </Typography>
            </TimelineOppositeContent>

            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot color="primary">
                <img src={uaLogo} alt="UA" className={classes.logo} />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>

            <TimelineContent sx={{ py: "100px", px: 2 }}>
              <Typography variant="h5" component="span">
                University of Aveiro
              </Typography>
              <Typography variant="body2" color="#f61329">
                Sep 2016 - Sep 2019
              </Typography>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </Grid>

      <Grid item xs={1} />
      <Grid item xs={4}>
        <Typography className={classes.subtitle} variant="h3">
          Extra formation
        </Typography>
        <br />
        <br />

        <Link
          href="https://www.udemy.com/course/the-complete-web-development-bootcamp/"
          underline="none"
          rel="noreferrer"
          target="_blank"
        >
          <CourseCard
            name="The Complete 2023 Web Development Bootcamp"
            authors={["Dr. Angela Yu"]}
            provider="Udemy"
          />
        </Link>

        <br />
        <br />

        <Link
          href="https://www.udemy.com/course/react-the-complete-guide-incl-redux/"
          underline="none"
          rel="noreferrer"
          target="_blank"
        >
          <CourseCard
            name="React - The Complete Guide 2023 (incl. React Router and Redux)"
            authors={["Maximilian Schwarzmüller"]}
            provider="Udemy"
          />
        </Link>

        <br />
        <br />

        <Link
          href="https://www.udemy.com/course/machinelearning/"
          underline="none"
          rel="noreferrer"
          target="_blank"
        >
          <CourseCard
            name="Machine Learning A-Z&trade;: Hands-On Python & R In Data Science"
            authors={["Kirill Eremenko", "Hadelin de Ponteves"]}
            provider="Udemy"
          />
        </Link>

        <br />
        <br />
      </Grid>
      <Grid item xs={1} />
    </Grid>
  );
}
