import {makeStyles} from "@mui/styles";

const AboutStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.primary.main
    },
    title: {
        textAlign: "center",
        paddingTop: "3%",
        color: theme.palette.secondary.dark
    },
    subTitle: {
        fontWeight: "600"
    },
    pictureSpace: {
        height: "100%",
        display: "flex",
    },
    picture: {
        transition: '0.3s',
        width: "75%",
        height: "auto",
        borderRadius: "50%",
        "&:hover": {
            transition: "0.3s",
            boxShadow: "0 12px 24px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
        }
    },
    space: {
        paddingBottom: "2%"
    },
    link: {
        textDecoration: 'none'
    },
    button: {
        button: {
            backgroundColor: theme.palette.secondary.dark,
            borderRadius: 20,
            color: "#ffffff",
            marginRight: "2%",
            textTransform: "none",
            width: "30%",
            height: "auto",
            "&:hover": {
                transition: "0.3s",
                color: "#000000",
                backgroundColor: "#EDF1FA",
            }
        },
    },
    bottom: {
        paddingBottom: "7%"
    }
}));

export default AboutStyles;