import { useEffect, useRef, useState } from "react";
import NavbarStyles from "./navbar-styles";
import { AppBar, Grid, Toolbar } from "@mui/material";
import { Link } from "react-scroll";
import "./navbar.css";

export default function Navbar() {
  const classes = NavbarStyles();
  const [background, setBackground] = useState("navbarTransparent");

  const navRef = useRef();
  navRef.current = background;

  const navbarStyle = {
    transition: "0.5s",
    background: background === "navbarTransparent" ? "inherit" : "white",
  };

  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 0.1;
      if (show) {
        setBackground("navbarSolid");
      } else {
        setBackground("navbarTransparent");
      }
    };
    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <AppBar elevation={0} sx={navbarStyle}>
      <Grid container>
        <Grid item xs={1} />
        <Grid item xs={11}>
          <Toolbar className="toolbar">
            <Link
              className={classes.link}
              smooth={true}
              duration={1000}
              to="home"
            >
              <span
                className={
                  background === "navbarTransparent"
                    ? classes.tabsTransparent
                    : classes.tabsSolid
                }
              >
                HOME
              </span>
            </Link>

            <Link
              className={classes.link}
              smooth={true}
              offset={-50}
              duration={1000}
              to="about"
            >
              <span
                className={
                  background === "navbarTransparent"
                    ? classes.tabsTransparent
                    : classes.tabsSolid
                }
              >
                ABOUT
              </span>
            </Link>

            <Link
              className={classes.link}
              smooth={true}
              offset={-70}
              duration={1000}
              to="education"
            >
              <span
                className={
                  background === "navbarTransparent"
                    ? classes.tabsTransparent
                    : classes.tabsSolid
                }
              >
                EDUCATION
              </span>
            </Link>

            <Link
              className={classes.link}
              smooth={true}
              offset={-50}
              duration={1000}
              to="experience"
            >
              <span
                className={
                  background === "navbarTransparent"
                    ? classes.tabsTransparent
                    : classes.tabsSolid
                }
              >
                EXPERIENCE
              </span>
            </Link>

            <Link
              className={classes.link}
              smooth={true}
              offset={-50}
              duration={1000}
              to="skills"
            >
              <span
                className={
                  background === "navbarTransparent"
                    ? classes.tabsTransparent
                    : classes.tabsSolid
                }
              >
                SKILLS
              </span>
            </Link>

            <Link
              className={classes.link}
              smooth={true}
              offset={-70}
              duration={1000}
              to="projects"
            >
              <div
                className={
                  background === "navbarTransparent"
                    ? classes.tabsTransparent
                    : classes.tabsSolid
                }
              >
                PROJECTS
              </div>
            </Link>
          </Toolbar>
        </Grid>
      </Grid>
    </AppBar>
  );
}
