import Particles from "react-tsparticles";
import "./particles.css";
import { useCallback } from "react";
import { loadSlim } from "tsparticles-slim";

export default function ParticlesPanel() {
  const particlesInit = useCallback(async (engine) => {
    await loadSlim(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {}, []);

  return (
    <Particles
      id="tsparticles"
      init={particlesInit}
      loaded={particlesLoaded}
      options={{
        background: {
          color: {
            value: "#f61329",
          },
          size: "cover",
        },
        fpsLimit: 120,
        interactivity: {
          detectsOn: "window",
          events: {
            onClick: {
              enable: true,
              mode: "push",
            },
            onHover: {
              enable: true,
              mode: "repulse",
              parallax: {
                force: 60,
              },
            },
            resize: true,
          },
          modes: {
            push: {
              quantity: 4
            },
            repulse: {
              distance: 200,
              duration: 0.4
            }
          },
        },
        particles: {
          color: {
            value: "#ffffff",
          },
          links: {
            color: "#ffffff",
            distance: 150,
            enable: true,
            opacity: 0.8,
            width: 3,
          },
          move: {
            direction: "none",
            enable: true,
            outModes: {
              default: "bounce"
            },
            random: false,
            speed: 3,
            straight: false
          },
          number: {
            density: {
              enable: true,
              area: 800
            },
            value: 40,
          },
          opacity: {
            value: 0.8,
            animation: {
              enable: true,
              minimumValue: 0.1,
              speed: 1,
            },
          },
          shape: {
            type: "circle",
          },
          size: {
            value: 2,
            animation: {
              minimumValue: 10,
              speed: 3,
            },
          },
          stroke: {
            width: 1,
            color: {
              value: "#ffffff",
              animation: {
                enable: false,
                speed: 1,
                sync: true,
              },
            },
          },
        },
      }}
    />
  );
}
